@import '../../../../../assets/scss/commons/commons.scss';

.lock-game-main .lock-controls {
    @include flex-grow(1);

    .control-step, .control-full {
        @include grow-hover();
        width: 100%;
    }

    img {
        display: block;
        height: 10em;
        margin-top: 2em;
    }

    &.ccw {
        margin-right: 2em;

        img {
            margin-left: auto;
        }
    }

    &.cw {
        margin-left: 2em;

        img {
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
    }
}