@import '../../../../assets/scss/commons/commons.scss';

.lock-game-instruction-button {
    width: 10em;
}

.lock-game-instruction-popup {
    ul {
        list-style-image: url('/images/general/list-item.png') !important;
        padding-left: 1.5em !important;
        @include bodyFont();
        display: flex;
        flex-direction: column;
        align-items: center;

        li {
                margin-bottom: 0.6em;

                &:last-child {
                    margin-bottom: 0px;
                }
        }
    }
}

.lock-game-main {
    @include fullsize();

    .game-bg {
        @include game-background('/images/game/lock/background.svg');
    }

    .lock-container {
        @include flex-center-content();

        .animated-element {
            height: 100%;
        }
    }

    .instruction-overlay {
        position: fixed;
        z-index: 5;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100vh;
        background-color: black;
        opacity: 0.5;
    }

    .shake {
        animation: shake 0.5s; 
        animation-iteration-count: infinite;
    }

    @keyframes shake {
        0% { transform: translate(1px, 1px) rotate(0deg); }
        10% { transform: translate(-1px, -2px) rotate(-1deg); }
        20% { transform: translate(-3px, 0px) rotate(1deg); }
        30% { transform: translate(3px, 2px) rotate(0deg); }
        40% { transform: translate(1px, -1px) rotate(1deg); }
        50% { transform: translate(-1px, 2px) rotate(-1deg); }
        60% { transform: translate(-3px, 1px) rotate(0deg); }
        70% { transform: translate(3px, 1px) rotate(-1deg); }
        80% { transform: translate(-1px, -1px) rotate(1deg); }
        90% { transform: translate(1px, 2px) rotate(0deg); }
        100% { transform: translate(1px, -2px) rotate(-1deg); }
    }
}