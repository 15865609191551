@import '../../../../assets/scss/commons/commons.scss';

.deplacements-game-main {
    @include fullsize();

    .question {
        @include bg-full('/images/game/professionals/instruction_bg.png');
        position: absolute;
        width: 65%;
        right: 15%;
        text-align: center;
        top: 8%;
        font-size: 1vh;
        padding: 3% 8% 4% 7%;

        .name-box {
            @include bg-full('/images/game/professionals/name_bg.png');
            position: absolute;
            font-size: 2.8em;
            font-weight: 600;
            padding: 1.5em 1.2em 1.2em 1.5em;
            top: 10%;
            left: -5%;

        }

        .text {
            font-size: 2.8em;
        }

        .description {
            font-weight: 600;
            margin-bottom: 1em;
        }

        .title{
            @include bg-full('/images/game/professionals/name_bg.png');
            position: absolute;
            font-size: 2.5em;
            padding: 1.5em 1.2em 1.2em 1.5em;
            top: -2em;
            left: 50%;
            transform: translateX(-50%);
            z-index: 5;
            font-family: 'Baloo Thambi', cursive;
        }
    }

    .timer-display {
        border-radius: 50%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        
        .timer-display_inner {
            font-size: 26em;
        }
    }
}