@import '../../../../assets/scss/commons/commons.scss';

.professionals-game-intro {
    @include fullsize();

    .person {
        display: block;
        width: 100%;
    }

    .person-container {
        overflow: hidden;
    }
}

.professionals-intro-popup-content {
    @include bg-contain('/images/games/professionals/intro_popup_bg.svg');

    h2, h3 {
        color: $color-purple;
        text-transform: uppercase;
    }

    h2 {
        font-size: 4em;
        margin: 0em 0em 0.2em 0em;
    }

    h3 {
        font-size: 2em;
        margin: 1em 0em 0em 0em;
    }

    p {
        font-size: 1.8em;
        margin: 0.2em 0em 0em 0em;
    }

    .next-button {
        font-size: 2em !important;
        margin-top: 1.5em;
    }
}