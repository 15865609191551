@import url(https://fonts.googleapis.com/css?family=Baloo+Thambi|Open+Sans:400,600,700&display=swap);
/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.static-game-header {
  height: 90vh;
  width: 100%;
  background-color: #ffe454;
  position: relative;
  padding-top: 4em;
  font-size: 1vh; }
  .static-game-header .static-game-header__description {
    font-size: 2.7em;
    margin-top: 0.6em;
    font-weight: normal; }
  .static-game-header .container {
    background-image: url("/images/static/hero_kids.png");
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    background-position: right;
    margin-right: 15px; }
  .static-game-header .content {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    height: 100%;
    width: 60%; }
    .static-game-header .content .text {
      padding-left: 100px;
      padding-top: 15em; }
      .static-game-header .content .text h1 {
        text-shadow: 8px 8px 20px rgba(0, 0, 0, 0.4); }
      .static-game-header .content .text .logo {
        display: flex;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        margin-bottom: 2%;
        position: absolute;
        z-index: 2;
        top: 2%;
        height: 15em; }
        .static-game-header .content .text .logo img {
          height: 100%; }
      .static-game-header .content .text .credits {
        font-family: 'Baloo Thambi', cursive;
        font-weight: normal;
        font-size: 1.7em;
        margin-left: 2em;
        margin-top: 1em; }
    .static-game-header .content .hand {
      -moz-flex-grow: 1;
      -ms-flex-grow: 1;
      flex-grow: 1;
      overflow: hidden;
      padding-left: 4%;
      padding-top: 2%; }
      .static-game-header .content .hand .hand__container {
        position: relative; }
      .static-game-header .content .hand .hand__phone {
        width: 70%;
        display: block; }
      .static-game-header .content .hand .btn-play {
        position: absolute;
        top: 20%;
        right: 20%;
        display: inline-block;
        margin-top: 1em;
        width: 35%;
        max-width: 350px;
        z-index: 10; }
        .static-game-header .content .hand .btn-play img {
          display: block;
          width: 100%; }
  .static-game-header h1 {
    font-weight: 700;
    margin: 0px;
    font-size: 8em;
    color: #ffffff;
    font-weight: normal;
    line-height: 0.9;
    margin-bottom: 0.2em; }
  .static-game-header p {
    color: #000000;
    font-size: 3em;
    margin: 0px;
    font-weight: bold; }
  @media only screen and (max-width: 1024px) {
    .static-game-header {
      height: auto;
      font-size: 1vw; }
      .static-game-header .container {
        margin-right: 0px; }
      .static-game-header .content {
        width: 100%; }
        .static-game-header .content .hand img {
          width: 40%; }
        .static-game-header .content .text {
          padding: 0px 9em; }
          .static-game-header .content .text .logo {
            -moz-flex-direction: column;
            flex-direction: column;
            -moz-justify-content: center;
            -ms-justify-content: center;
            justify-content: center;
            -ms-flex-pack: center;
            width: 100%;
            position: relative;
            top: -2em; }
          .static-game-header .content .text .credits {
            font-size: 2em;
            margin-left: 0em;
            margin-top: 1em; }
      .static-game-header p {
        max-width: 50%; }
      .static-game-header .container {
        background-size: 50%;
        background-position: bottom right 15px; } }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.scroll-bar {
  position: relative;
  height: 100%;
  text-align: left; }
  .scroll-bar .indicator {
    width: 100%;
    height: 50px;
    position: absolute;
    opacity: 1;
    pointer-events: none;
    transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    -o-transition: opacity 0.5s; }
    .scroll-bar .indicator.hidden {
      opacity: 0; }
    .scroll-bar .indicator.bottom {
      bottom: 0px;
      background: #ffffff00;
      /* Old browsers */
      /* FF3.6+ */
      /* Chrome,Safari4+ */
      /* Chrome10+,Safari5.1+ */
      /* Opera 11.10+ */
      /* IE10+ */
      background: linear-gradient(to bottom, #ffffff00 0%, #ffffffff 100%);
      /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
      /* IE6-9 */ }
    .scroll-bar .indicator.top {
      top: 0px;
      background: #ffffffff;
      /* Old browsers */
      /* FF3.6+ */
      /* Chrome,Safari4+ */
      /* Chrome10+,Safari5.1+ */
      /* Opera 11.10+ */
      /* IE10+ */
      background: linear-gradient(to bottom, #ffffffff 0%, #ffffff00 100%);
      /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
      /* IE6-9 */ }
  .scroll-bar [data-scrollbar] {
    padding-right: 2em; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.static-footer {
  background-color: #666bff;
  border-top: 1px solid #d3d3d3;
  padding: 60px 80px 30px 80px; }
  .static-footer .copyright {
    margin-top: 60px;
    text-align: center; }
    .static-footer .copyright a:hover {
      text-decoration: underline; }
  .static-footer .content {
    max-width: 1400px;
    margin: auto;
    font-size: 16px;
    color: white;
    display: flex;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
  .static-footer .logo {
    margin-bottom: 10px;
    width: 262px; }
  .static-footer .form-cta .text {
    font-family: 'Baloo Thambi', cursive;
    font-weight: normal;
    margin-right: 20px;
    font-size: 36px;
    line-height: 0.8;
    color: #ffffff; }
    .static-footer .form-cta .text span {
      font-size: 22px; }
  .static-footer .cta {
    transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
    -moz-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
    -o-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
    font-family: 'Baloo Thambi', cursive;
    font-weight: normal;
    text-align: center;
    font-size: 24px;
    line-height: 0.8;
    text-transform: uppercase;
    background-image: url("/images/static/footer_btn.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 1.3em 1.7em;
    display: inline-block;
    margin-top: 1em;
    white-space: nowrap;
    color: black;
    margin-left: 45px; }
    .static-footer .cta:hover {
      transform: scale(1.1); }
  .static-footer .logo-column {
    max-width: 270px; }
  .static-footer .column {
    /*&:nth-child(3) {
            @include flex-grow(1);
        }

        &:nth-child(4) {
            @include flex-shrink(1);
        }*/ }
    .static-footer .column:not(:first-child) {
      margin-left: 60px; }
    .static-footer .column ul {
      padding: 0px;
      list-style: none; }
      .static-footer .column ul li {
        margin-bottom: 1em; }
      .static-footer .column ul a:hover {
        text-decoration: underline; }
      .static-footer .column ul li {
        margin-bottom: 0.4em; }
    .static-footer .column h3 {
      margin-bottom: 0.1em; }
      .static-footer .column h3:not(:first-child) {
        margin-top: 2em; }
  .static-footer .logo-rrm {
    width: 100%;
    max-width: 300px;
    margin: 1.4em 0;
    display: block;
    min-width: 230px; }
  .static-footer .logo-qc {
    width: 100%;
    max-width: 200px;
    margin: 1.7em 0;
    display: block; }
  .static-footer .last-update {
    color: black;
    margin-top: 0px;
    font-size: 0.8em;
    text-align: center; }
  .static-footer .static-footer__links {
    display: flex;
    justify-content: center;
    align-items: center; }
  @media only screen and (max-width: 1362px) {
    .static-footer .form-cta .text {
      font-size: 26px; }
      .static-footer .form-cta .text span {
        font-size: 14px; } }
  @media only screen and (max-width: 1215px) {
    .static-footer .content {
      -moz-flex-direction: column;
      flex-direction: column;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      text-align: center; }
    .static-footer .form-cta {
      -moz-flex-direction: column;
      flex-direction: column; }
    .static-footer .cta {
      margin-top: 25px;
      margin-bottom: 45px;
      margin-left: 0px; }
    .static-footer .column:not(:first-child) {
      margin-left: 0px; }
    .static-footer .logo-rrm {
      margin-left: auto;
      margin-right: auto; }
    .static-footer .logo-qc {
      margin-left: auto;
      margin-right: auto; } }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

@keyframes float-1 {
  0% {
    transform: translateY(-6px); }
  70% {
    transform: translateY(6px); }
  100% {
    transform: translateY(-6px); } }

@keyframes float-2 {
  0% {
    transform: translateY(-7px); }
  70% {
    transform: translateY(7px); }
  100% {
    transform: translateY(-7px); } }

@keyframes float-3 {
  0% {
    transform: translateY(-8px); }
  70% {
    transform: translateY(8px); }
  100% {
    transform: translateY(-8px); } }

.floating-button-1 {
  transform: translateY(0px);
  animation: float-1 4s ease-in-out infinite; }

.floating-button-2 {
  transform: translateY(0px);
  animation: float-2 5s ease-in-out infinite; }

.floating-button-3 {
  transform: translateY(0px);
  animation: float-3 6s ease-in-out infinite; }

.btn-grow-hover {
  width: 100%;
  transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
  -moz-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
  -o-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer; }
  .btn-grow-hover:hover {
    transform: scale(1.1); }

@media only screen and (max-width: 375px) {
  .shaped-div-container > div:not(.shaped-div-content) {
    display: none; } }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.game .info-button {
  transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
  -moz-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
  -o-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  position: absolute;
  top: 3vh;
  right: 10vh;
  width: 10vh;
  max-width: 105px;
  z-index: 10; }
  .game .info-button:hover {
    transform: scale(1.1); }
  .game .info-button img {
    display: block;
    width: 100%; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.lock-game-main .lock {
  position: relative;
  height: 100%; }
  .lock-game-main .lock .base {
    display: block;
    height: 100%; }
  .lock-game-main .lock .ring {
    position: absolute;
    width: 36%;
    left: 29.5%;
    top: 42.5%; }
    .lock-game-main .lock .ring > img {
      position: relative;
      z-index: 0;
      display: block;
      width: 100%; }
    .lock-game-main .lock .ring > svg {
      position: absolute;
      z-index: 1;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%; }
    .lock-game-main .lock .ring text {
      fill: white; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.lock-game-main .instructions {
  background-image: url("/images/game/lock/instructions_bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  position: relative;
  z-index: 6; }
  .lock-game-main .instructions .content {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    font-size: 1em;
    width: 100%;
    height: 80%;
    position: relative;
    left: -10%; }
  .lock-game-main .instructions .step {
    background-image: url("/images/game/lock/header_number_bg.png");
    background-size: contain;
    background-repeat: no-repeat;
    font-family: 'Baloo Thambi', cursive;
    font-weight: normal;
    background-position: center;
    font-size: 32em;
    color: #3b3b3b;
    padding: 1.5em;
    margin-right: 1em; }
  .lock-game-main .instructions .text {
    color: #828282;
    font-weight: 500;
    font-size: 13em; }
  .lock-game-main .instructions .next {
    background-image: url("/images/game/lock/skip_instruction.png");
    background-size: contain;
    background-repeat: no-repeat;
    transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
    -moz-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
    -o-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
    position: absolute;
    bottom: -10%;
    right: 8%;
    background-position: center;
    width: 8%; }
    .lock-game-main .instructions .next:hover {
      transform: scale(1.1); }
    .lock-game-main .instructions .next:after {
      content: "";
      display: block;
      padding-bottom: 100%; }
  .lock-game-main .instructions img {
    height: 1em; }
    .lock-game-main .instructions img.reversed {
      transform: scaleX(-1); }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.lock-game-main .lock-controls {
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1; }
  .lock-game-main .lock-controls .control-step, .lock-game-main .lock-controls .control-full {
    transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
    -moz-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
    -o-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
    width: 100%; }
    .lock-game-main .lock-controls .control-step:hover, .lock-game-main .lock-controls .control-full:hover {
      transform: scale(1.1); }
  .lock-game-main .lock-controls img {
    display: block;
    height: 10em;
    margin-top: 2em; }
  .lock-game-main .lock-controls.ccw {
    margin-right: 2em; }
    .lock-game-main .lock-controls.ccw img {
      margin-left: auto; }
  .lock-game-main .lock-controls.cw {
    margin-left: 2em; }
    .lock-game-main .lock-controls.cw img {
      transform: scaleX(-1); }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.lock-game-main .code-display {
  padding-top: 7em;
  padding-bottom: 2em;
  padding-left: 3em; }
  .lock-game-main .code-display.code-display-fr {
    background-image: url("/images/game/lock/numbers_bg_fr.png");
    background-size: contain;
    background-repeat: no-repeat; }
  .lock-game-main .code-display.code-display-en {
    background-image: url("/images/game/lock/numbers_bg_en.png");
    background-size: contain;
    background-repeat: no-repeat; }
  .lock-game-main .code-display .combination {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    height: 100%;
    padding: 20% 15%; }
  .lock-game-main .code-display .combination-number {
    font-family: 'Baloo Thambi', cursive;
    font-weight: normal;
    text-align: center;
    font-size: 17em;
    transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
    -moz-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
    -o-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
    color: #3b3b3b; }
    .lock-game-main .code-display .combination-number.completed {
      transform: scale(1.2);
      color: #129977; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.lock-game-main .level-display {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }
  .lock-game-main .level-display .level {
    margin-bottom: 2em; }
    .lock-game-main .level-display .level:last-child {
      margin-bottom: 0; }
    .lock-game-main .level-display .level .level-container {
      width: 100%;
      position: relative; }
      .lock-game-main .level-display .level .level-container:after {
        content: "";
        display: block;
        padding-bottom: 125%; }
    .lock-game-main .level-display .level .level-content {
      background-image: url("/images/game/lock/step_number_bg.png");
      background-size: contain;
      background-repeat: no-repeat;
      display: flex;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center;
      font-family: 'Baloo Thambi', cursive;
      font-weight: normal;
      position: absolute;
      width: 100%;
      height: 100%;
      padding-top: 0.2em;
      font-size: 14em;
      color: #6d4d4e; }
    .lock-game-main .level-display .level .completed {
      position: absolute;
      height: 139%;
      top: -21%;
      left: -44%;
      display: block; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.lock-game-instruction-button {
  width: 10em; }

.lock-game-instruction-popup ul {
  list-style-image: url("/images/general/list-item.png") !important;
  padding-left: 1.5em !important;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .lock-game-instruction-popup ul li {
    margin-bottom: 0.6em; }
    .lock-game-instruction-popup ul li:last-child {
      margin-bottom: 0px; }

.lock-game-main {
  width: 100%;
  height: 100vh;
  overflow: hidden; }
  .lock-game-main .game-bg {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    background-image: url("/images/game/lock/background.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
  .lock-game-main .lock-container {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
    .lock-game-main .lock-container .animated-element {
      height: 100%; }
  .lock-game-main .instruction-overlay {
    position: fixed;
    z-index: 5;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: black;
    opacity: 0.5; }
  .lock-game-main .shake {
    animation: shake 0.5s;
    animation-iteration-count: infinite; }

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg); }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg); }
  20% {
    transform: translate(-3px, 0px) rotate(1deg); }
  30% {
    transform: translate(3px, 2px) rotate(0deg); }
  40% {
    transform: translate(1px, -1px) rotate(1deg); }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg); }
  60% {
    transform: translate(-3px, 1px) rotate(0deg); }
  70% {
    transform: translate(3px, 1px) rotate(-1deg); }
  80% {
    transform: translate(-1px, -1px) rotate(1deg); }
  90% {
    transform: translate(1px, 2px) rotate(0deg); }
  100% {
    transform: translate(1px, -2px) rotate(-1deg); } }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.exit-button {
  position: absolute;
  z-index: 10;
  top: 2vh;
  left: 2vh; }
  .exit-button .exit-button__icon {
    background-image: url("/images/popup/popup_btn_home.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 3em;
    width: 3em; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.lock-game {
  width: 100%;
  height: 100vh;
  overflow: hidden; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.informations-component {
  height: 100%;
  width: 100%;
  overflow: hidden; }
  .informations-component .slides {
    position: relative;
    transition: left 0.5s;
    -moz-transition: left 0.5s;
    -o-transition: left 0.5s; }
  .informations-component .slide {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    text-align: left;
    position: relative;
    float: left; }
  .informations-component:after {
    display: block;
    content: '';
    clear: both; }
  .informations-component ul {
    list-style-image: url("/images/general/list-item.png") !important;
    padding-left: 1.5em !important;
    font-family: 'Open Sans', sans-serif; }
    .informations-component ul li {
      margin-bottom: 0.6em; }
      .informations-component ul li:last-child {
        margin-bottom: 0px; }
  .informations-component a {
    text-decoration: underline; }
  .informations-component blockquote {
    background-image: url("/images/general/quote.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding: 0.5em 2em 1.4em 2em;
    margin-top: 2em; }
  .informations-component h2 {
    font-size: 1.5em !important;
    margin: 0.8em 0px 0.5em 0px !important;
    color: #ff8e09; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.extracurricular-game-main {
  width: 100%;
  height: 100vh;
  overflow: hidden; }
  .extracurricular-game-main .activity-ball {
    font-size: 2vh;
    position: relative;
    z-index: 1; }
    .extracurricular-game-main .activity-ball.text {
      background-color: white; }
    .extracurricular-game-main .activity-ball .ball-content {
      transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
      -moz-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
      -o-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      cursor: pointer;
      width: 100%;
      height: 100%;
      padding: 5%; }
      .extracurricular-game-main .activity-ball .ball-content:hover {
        transform: scale(1.1); }
      .extracurricular-game-main .activity-ball .ball-content img {
        width: 120%;
        display: block;
        pointer-events: none;
        position: absolute;
        left: -10%;
        top: -10%; }
    .extracurricular-game-main .activity-ball:hover {
      z-index: 2; }
  .extracurricular-game-main .world {
    position: relative;
    top: -400px; }
  .extracurricular-game-main .instructions {
    background-image: url("/images/game/extracurricular/instructions_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    position: relative;
    z-index: 5; }
    .extracurricular-game-main .instructions .school {
      background-image: url("/images/game/extracurricular/school_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      font-family: 'Baloo Thambi', cursive;
      font-weight: normal;
      position: absolute;
      font-size: 2.35em;
      padding: 1.5em 2em 2em 1em;
      text-transform: uppercase;
      left: -5%;
      width: 45%;
      text-align: center; }
    .extracurricular-game-main .instructions .text {
      font-size: 1.75em;
      padding-left: 45%;
      padding-right: 10%;
      padding-top: 2.5em; }
    .extracurricular-game-main .instructions .prev-button {
      position: absolute;
      bottom: -1em;
      right: 4em;
      font-size: 2em !important; }

.extracurricular-popup-content {
  text-align: left;
  /*.informations {
        margin-bottom: 2em;
    }*/ }
  .extracurricular-popup-content img {
    display: block;
    width: 60%;
    margin: auto; }
  .extracurricular-popup-content .popup-actions {
    font-size: 1em; }
  .extracurricular-popup-content .schools {
    list-style: none;
    padding: 0px; }
    .extracurricular-popup-content .schools li {
      display: inline-block;
      margin-right: 1em;
      white-space: nowrap; }
  .extracurricular-popup-content h3 {
    margin-bottom: 0px; }
  .extracurricular-popup-content ul {
    margin: 15px 0px 0px 0px; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.extracurricular-game {
  width: 100%;
  height: 100vh;
  overflow: hidden; }
  .extracurricular-game .game-bg {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    background-image: url("/images/game/extracurricular/background.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.professionals-game-main {
  width: 100%;
  height: 100vh;
  overflow: hidden; }
  .professionals-game-main .characters {
    display: flex;
    height: 66vh;
    width: 100%;
    padding: 0px 5%;
    position: relative;
    top: 34vh;
    z-index: 1; }
  .professionals-game-main .character {
    transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
    -moz-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
    -o-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
    transform-origin: bottom;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
    width: 25%;
    position: relative; }
    .professionals-game-main .character:hover {
      transform: scale(1.05); }
    .professionals-game-main .character img {
      display: block;
      height: 100%;
      margin: auto; }
    .professionals-game-main .character .name-container {
      display: flex;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center;
      font-family: 'Baloo Thambi', cursive;
      font-weight: normal;
      position: absolute;
      height: 25%;
      text-align: center;
      font-size: 3.4vh;
      width: 100%;
      padding: 0 20%; }
    .professionals-game-main .character.character-0 .name-container {
      top: 37%;
      transform: rotate(-1.7deg); }
    .professionals-game-main .character.character-1 .name-container {
      top: 34%;
      transform: rotate(1deg); }
    .professionals-game-main .character.character-2 .name-container {
      top: 38.8%;
      transform: rotate(1.1deg); }
    .professionals-game-main .character.character-3 .name-container {
      top: 40.2%;
      transform: rotate(0.7deg); }
    .professionals-game-main .character.character-4 .name-container {
      top: 39.3%;
      transform: rotate(2deg); }
    .professionals-game-main .character.character-5 .name-container {
      top: 37.5%;
      transform: rotate(1.7deg); }
    .professionals-game-main .character.selected-option {
      opacity: 0; }
    .professionals-game-main .character.character-temp {
      position: absolute;
      height: 66vh;
      z-index: 600;
      bottom: 0px;
      pointer-events: none;
      left: 21%; }
  .professionals-game-main .instructions {
    background-image: url("/images/game/professionals/instruction_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    width: 65%;
    right: 15%;
    top: 3%;
    font-size: 1vh;
    padding: 3% 8% 4% 8%; }
    .professionals-game-main .instructions .name-box {
      background-image: url("/images/game/professionals/name_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: absolute;
      font-size: 2.8em;
      font-weight: 600;
      padding: 1.5em 1.2em 1.2em 1.5em;
      top: 10%;
      left: -5%; }
    .professionals-game-main .instructions .text {
      font-size: 2.8em; }
    .professionals-game-main .instructions .description {
      font-weight: 600;
      margin-bottom: 1em; }

.professionals-success-popup-content {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  padding-left: 40%;
  height: 100%; }
  .professionals-success-popup-content p {
    text-align: left;
    font-size: 1.1em;
    margin: 0.5em 0em; }
  .professionals-success-popup-content h2 {
    text-transform: uppercase;
    font-size: 2.4em;
    color: #7c56dd;
    margin-bottom: 0.1em; }
  .professionals-success-popup-content .next-button {
    margin-top: 1.0em; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.professionals-game-intro {
  width: 100%;
  height: 100vh;
  overflow: hidden; }
  .professionals-game-intro .person {
    display: block;
    width: 100%; }
  .professionals-game-intro .person-container {
    overflow: hidden; }

.professionals-intro-popup-content {
  background-image: url("/images/games/professionals/intro_popup_bg.svg");
  background-size: contain;
  background-repeat: no-repeat; }
  .professionals-intro-popup-content h2, .professionals-intro-popup-content h3 {
    color: #7c56dd;
    text-transform: uppercase; }
  .professionals-intro-popup-content h2 {
    font-size: 4em;
    margin: 0em 0em 0.2em 0em; }
  .professionals-intro-popup-content h3 {
    font-size: 2em;
    margin: 1em 0em 0em 0em; }
  .professionals-intro-popup-content p {
    font-size: 1.8em;
    margin: 0.2em 0em 0em 0em; }
  .professionals-intro-popup-content .next-button {
    font-size: 2em !important;
    margin-top: 1.5em; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.professionals-game {
  width: 100%;
  height: 100vh;
  overflow: hidden; }
  .professionals-game .game-bg {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    background-image: url("/images/game/professionals/background.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.locker-game-main {
  width: 100%;
  height: 100vh;
  overflow: hidden; }
  .locker-game-main .go-button {
    width: 40%; }
  .locker-game-main .content .animated-element {
    height: 100%; }
  .locker-game-main .content p {
    font-family: 'Baloo Thambi', cursive;
    font-weight: normal;
    font-size: 6em;
    margin-top: 0px; }
  .locker-game-main .content .btn-next {
    font-size: 3em !important; }
  .locker-game-main .step {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    height: 100%; }
  .locker-game-main .step-0, .locker-game-main .step-2 {
    padding-bottom: 30%; }
  .locker-game-main .step-1, .locker-game-main .step-3 {
    padding-bottom: 10%; }
  .locker-game-main .dark-items {
    display: flex;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    width: 80%;
    margin-top: 2em; }
    .locker-game-main .dark-items .list-item {
      padding: 3.5%; }
      .locker-game-main .dark-items .list-item img {
        display: block;
        width: 100%; }
  .locker-game-main .locker img {
    display: block;
    height: 100%; }
  .locker-game-main .locker.active {
    cursor: pointer; }
  .locker-game-main .timer {
    margin-top: 4em; }
    .locker-game-main .timer .text {
      background-image: url("/images/game/locker/time_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      font-family: 'Baloo Thambi', cursive;
      font-weight: normal;
      display: inline-block;
      padding: 1.2em 2em 1em 2em;
      font-size: 4em;
      position: relative; }
    .locker-game-main .timer .countdown {
      background-image: url("/images/game/locker/countdown_bg.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: left;
      font-size: 3em;
      width: 1.4em;
      height: 1.4em;
      position: absolute;
      right: -1.1em;
      top: -0.1em;
      text-align: center;
      line-height: 1.35em;
      color: #231f20; }
      .locker-game-main .timer .countdown.alert {
        color: #c32c36;
        background-position: right; }
      .locker-game-main .timer .countdown span {
        font-size: 0.8em; }
  .locker-game-main .current-item {
    background-image: url("/images/game/locker/item_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    display: inline-block;
    width: 59%;
    height: 12em;
    padding: 0% 22% 1.5% 4%;
    position: relative; }
    .locker-game-main .current-item .text {
      font-size: 3em;
      font-weight: 600;
      height: 100%;
      display: flex;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center; }
    .locker-game-main .current-item img {
      max-height: 15em;
      position: absolute;
      right: 8%;
      bottom: 8%; }
  .locker-game-main .item-click {
    position: absolute; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.locker-game-instructions {
  width: 100%;
  height: 100vh;
  overflow: hidden; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.locker-game {
  width: 100%;
  height: 100vh;
  overflow: hidden; }
  .locker-game .game-bg {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    background-image: url("/images/game/locker/background.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.deplacements-game-main {
  width: 100%;
  height: 100vh;
  overflow: hidden; }
  .deplacements-game-main .question {
    background-image: url("/images/game/professionals/instruction_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    width: 65%;
    right: 15%;
    text-align: center;
    top: 8%;
    font-size: 1vh;
    padding: 3% 8% 4% 7%; }
    .deplacements-game-main .question .name-box {
      background-image: url("/images/game/professionals/name_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: absolute;
      font-size: 2.8em;
      font-weight: 600;
      padding: 1.5em 1.2em 1.2em 1.5em;
      top: 10%;
      left: -5%; }
    .deplacements-game-main .question .text {
      font-size: 2.8em; }
    .deplacements-game-main .question .description {
      font-weight: 600;
      margin-bottom: 1em; }
    .deplacements-game-main .question .title {
      background-image: url("/images/game/professionals/name_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: absolute;
      font-size: 2.5em;
      padding: 1.5em 1.2em 1.2em 1.5em;
      top: -2em;
      left: 50%;
      transform: translateX(-50%);
      z-index: 5;
      font-family: 'Baloo Thambi', cursive; }
  .deplacements-game-main .timer-display {
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center; }
    .deplacements-game-main .timer-display .timer-display_inner {
      font-size: 26em; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.answer-handle {
  user-drag: none;
  -webkit-user-select: none;
          user-select: none; }
  .answer-handle .answer-handle_inner {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-self: center; }
  .answer-handle .answer-handle_direction {
    display: flex;
    align-self: center; }
    .answer-handle .answer-handle_direction.button {
      background-image: url("/images/game/deplacements/bg_answer_button.svg");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
      -moz-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
      -o-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      cursor: pointer;
      height: 60em;
      width: 60em;
      display: flex;
      align-items: center;
      justify-content: center; }
      .answer-handle .answer-handle_direction.button:hover {
        transform: scale(1.1); }
      .answer-handle .answer-handle_direction.button .label {
        font-size: 15em;
        color: white;
        text-shadow: 0.03em 0.03em 0px #ca600a;
        font-family: 'Baloo Thambi', cursive;
        font-weight: normal;
        text-transform: uppercase; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.deplacements-game {
  width: 100%;
  height: 100vh;
  overflow: hidden; }
  .deplacements-game .game-bg {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    background-image: url("/images/game/deplacements/bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.vie-sociale-game-main {
  width: 100%;
  height: 100vh;
  overflow: hidden; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.vie-sociale-game {
  width: 100%;
  height: 100vh;
  overflow: hidden; }
  .vie-sociale-game .game-bg {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    background-image: url("/images/game/static/background.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.agenda-game-main {
  width: 100%;
  height: 100vh;
  overflow: hidden; }
  .agenda-game-main .ui .animated-element {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%; }
  .agenda-game-main .question {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    font-size: 2.2vh;
    flex-direction: column;
    padding: 2em; }
    .agenda-game-main .question .question-container {
      height: 100%;
      display: flex;
      align-items: center; }
      .agenda-game-main .question .question-container .question-content {
        background-image: url("/images/game/professionals/instruction_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: relative;
        margin: auto; }
      .agenda-game-main .question .question-container .title {
        background-image: url("/images/game/professionals/name_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: absolute;
        font-size: 1.2em;
        padding: 1.5em 1.2em 1.2em 1.5em;
        top: -2em;
        left: 50%;
        transform: translateX(-50%);
        font-family: 'Baloo Thambi', cursive; }
      .agenda-game-main .question .question-container .text {
        padding: 2.5em 5em 3em 6em; }
    .agenda-game-main .question .answers {
      display: flex;
      height: 60%; }
    .agenda-game-main .question .answer {
      background-image: url("/images/game/agenda/instructions_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 8vh 5vh 9vh 9vh;
      width: 50%;
      position: relative;
      text-align: center; }
      .agenda-game-main .question .answer .title {
        background-image: url("/images/game/professionals/name_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: absolute;
        font-size: 1em;
        padding: 1.5em 1.2em 1.2em 1.5em;
        top: -1em;
        left: 50%;
        transform: translateX(-50%);
        z-index: 5;
        font-family: 'Baloo Thambi', cursive; }
      .agenda-game-main .question .answer .button {
        position: absolute;
        bottom: -0.5em;
        width: 8em;
        left: 50%;
        transform: translateX(-50%); }

.agenda-result-image {
  display: inline-block;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  height: 100%; }
  .agenda-result-image img {
    display: block;
    height: 100%;
    max-width: 100%; }
    .agenda-result-image img.agenda-layer {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.agenda-game {
  width: 100%;
  height: 100vh;
  overflow: hidden; }
  .agenda-game .game-bg {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    background-image: url("/images/game/static/background.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.game-map .icon {
  position: absolute; }
  .game-map .icon .icon-container {
    transform: translate(-50%, -50%);
    width: 12vh;
    position: relative; }
  .game-map .icon a {
    display: block;
    width: 100%;
    height: 100%; }
  .game-map .icon .animated-content {
    width: 100%;
    height: 100%; }
  .game-map .icon .icon-content {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    border-radius: 50%;
    background-clip: padding-box;
    /* stops bg color from leaking outside the border: */
    transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
    -moz-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
    -o-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
    width: 100%;
    height: 100%; }
    .game-map .icon .icon-content:hover {
      transform: scale(1.1); }
    .game-map .icon .icon-content img {
      display: block;
      width: 100%;
      position: relative; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.game-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; }
  .game-popup .game-popup-description {
    font-size: 0.9em;
    line-height: 1.4; }

.btn-home {
  background-image: url("/images/popup/popup_btn_home.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 3em;
  width: 3em;
  margin: 0.5em 0 0 0.5em; }

.game-map {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative; }
  .game-map .map-bg {
    background: #ffffff;
    /* Old browsers */
    /* FF3.6+ */
    /* Chrome,Safari4+ */
    /* Chrome10+,Safari5.1+ */
    /* Opera 11.10+ */
    /* IE10+ */
    background: linear-gradient(to bottom, #ffffff 0%, #bbd2e4 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
    /* IE6-9 */
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    transition: 1s filter; }
  .game-map .map-main {
    width: 100%;
    height: 100%; }
  .game-map .btn-back {
    position: absolute;
    left: 2vh;
    top: 2vh; }
  .game-map.overlay-start .map-bg, .game-map.overlay-end .map-bg {
    filter: blur(4px); }
  .game-map .map-overlay {
    display: flex;
    width: 100%;
    height: 100vh;
    padding: 10vh 10% 0;
    position: fixed; }
    .game-map .map-overlay .overlay-character {
      width: 40%;
      height: 100%;
      overflow: hidden; }
      .game-map .map-overlay .overlay-character img {
        width: 100%; }
    .game-map .map-overlay .overlay-text {
      width: 60%;
      padding: 6%; }
      .game-map .map-overlay .overlay-text p {
        margin-bottom: 4vh;
        line-height: 1.4; }
        @media only screen and (max-width: 1280px) {
          .game-map .map-overlay .overlay-text p {
            font-size: 20px; } }
        @media only screen and (max-width: 800px) {
          .game-map .map-overlay .overlay-text p {
            font-size: 14px; } }
      @media only screen and (max-width: 800px) {
        .game-map .map-overlay .overlay-text {
          padding: 2%; } }
    .game-map .map-overlay .text-container {
      background-image: url("/images/popup/popup_bg.png");
      background-size: 100% 100%;
      padding: 4vh 8vh 6vh 8vh;
      text-align: center; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.game .menu {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  font-family: 'Baloo Thambi', cursive;
  font-weight: normal;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 999;
  pointer-events: none;
  font-size: 2.5vh; }
  .game .menu .menu-bg {
    width: 100%;
    height: 90%;
    position: relative;
    opacity: 0.95;
    top: 100%;
    transition: top 0.6s ease-in-out;
    -moz-transition: top 0.6s ease-in-out;
    -o-transition: top 0.6s ease-in-out; }
    .game .menu .menu-bg:before {
      position: absolute;
      top: 15%;
      content: '';
      display: block;
      width: 100%;
      height: 65%;
      background-color: black;
      transform: skew(0deg, -2.5deg); }
    .game .menu .menu-bg:after {
      position: absolute;
      top: 35%;
      content: '';
      display: block;
      width: 100%;
      height: 65%;
      background-color: black;
      transform: skew(0deg, 2deg); }
  .game .menu .menu-content {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    -moz-flex-direction: column;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 100%;
    transition: top 0.6s ease-in-out 0.1s;
    -moz-transition: top 0.6s ease-in-out 0.1s;
    -o-transition: top 0.6s ease-in-out 0.1s; }
  .game .menu .menu-title {
    color: #be2222;
    font-size: 5em;
    text-transform: uppercase; }
  .game .menu .menu-item {
    transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
    -moz-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
    -o-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
    color: #ffffff;
    font-size: 2em;
    margin-bottom: 0.5em; }
    .game .menu .menu-item:hover {
      transform: scale(1.1); }
  .game .menu .close-button {
    transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
    -moz-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
    -o-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
    position: absolute;
    top: 8%;
    right: 2%;
    color: #ffffff;
    font-size: 5em; }
    .game .menu .close-button:hover {
      transform: scale(1.1); }
    .game .menu .close-button span {
      transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84) 0s;
      -moz-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84) 0s;
      -o-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84) 0s;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transform: scale(0);
      display: block; }
  .game .menu.menu-opened {
    pointer-events: inherit; }
    .game .menu.menu-opened .menu-bg {
      top: 0%; }
    .game .menu.menu-opened .menu-content {
      top: 0%; }
    .game .menu.menu-opened .close-button span {
      transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84) 0.5s;
      -moz-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84) 0.5s;
      -o-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84) 0.5s;
      transform: scale(1); }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.game .fullscreen-button {
  transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
  -moz-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
  -o-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  position: fixed;
  bottom: 3vh;
  right: 3vh;
  width: 15vh;
  max-width: 100px;
  z-index: 1100; }
  .game .fullscreen-button:hover {
    transform: scale(1.1); }
  .game .fullscreen-button img {
    display: block;
    width: 100%; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.game {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  font-size: 24px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  /* KEYFRAMES
    ------------------------------------------- */
  /* Animate width + height */
  /* Add borders */
  /* fade-in  */
  /* Rotate device */ }
  .game .game-bg {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px; }
  .game .iconContainer {
    width: 200px;
    height: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -100px;
    margin-top: -100px; }
  .game .phone {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    position: relative;
    left: 50%;
    margin-left: -50px;
    width: 0;
    height: 0;
    border: 0 solid #000;
    background: #a9a9a9;
    border-radius: 10px;
    box-sizing: border-box; }
    .game .phone svg {
      font-size: 50px;
      color: #454545;
      opacity: 0; }
  .game .overlay {
    pointer-events: none; }
    .game .overlay .hint {
      text-transform: uppercase;
      font-size: 15px;
      text-align: center;
      white-space: nowrap;
      margin-top: 20px;
      font-weight: 600;
      opacity: 0;
      pointer-events: none;
      transition: opacity 1s; }
  @media only screen and (orientation: portrait) {
    .game .overlay {
      pointer-events: all;
      display: block;
      background-color: #ffffff;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1000000;
      top: 0px;
      left: 0px; }
      .game .overlay .hint {
        opacity: 1;
        transition-delay: 1s; }
    .game .overlay .phone {
      animation: sizeIncrease .5s forwards ease,  borderIncrease .5s .5s forwards ease,  rotateRight 2.8s 1s forwards ease infinite; }
    .game .overlay .phone svg {
      animation: fadeIn .5s .8s forwards ease; }
    .game .overlay .iconContainer p {
      animation: fadeIn .5s 1.3s forwards ease; } }

@keyframes sizeIncrease {
  0% {
    width: 0;
    height: 10px; }
  50% {
    width: 100px;
    height: 10px; }
  100% {
    width: 100px;
    height: 160px; } }

@keyframes borderIncrease {
  100% {
    border-width: 20px 10px; } }

@keyframes fadeIn {
  100% {
    color: #000;
    opacity: 1; } }

@keyframes rotateRight {
  25% {
    transform: rotate(90deg); }
  100% {
    transform: rotate(90deg); } }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.popup {
  z-index: 500; }
  .popup .popup-overlay {
    position: fixed;
    z-index: 501;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: black;
    transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    -o-transition: opacity 0.5s; }
  .popup .popup-container {
    background-image: url("/images/popup/popup_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 511;
    transform: scale(0);
    transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -o-transition: transform 0.5s; }
  .popup .popup-title-container {
    position: absolute;
    top: -4em;
    width: 100%;
    text-align: center; }
  .popup .popup-title {
    background-image: url("/images/popup/popup_title.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-family: 'Baloo Thambi', cursive;
    font-weight: normal;
    display: inline-block;
    font-size: 3.5em;
    text-transform: uppercase;
    color: #3b3b3b;
    padding: 1.2em 1em 0.8em 1em; }
    .popup .popup-title span {
      display: inline-block;
      transform: skewY(-3deg); }
  .popup .popup-content {
    text-align: center;
    font-size: 2em;
    height: 100%; }
    .popup .popup-content .scroll-content {
      padding-bottom: 30px; }
    .popup .popup-content p a, .popup .popup-content li a, .popup .popup-content h1 a, .popup .popup-content h2 a, .popup .popup-content h3 a, .popup .popup-content h4 a, .popup .popup-content h5 a, .popup .popup-content h6 a {
      text-decoration: underline; }
      .popup .popup-content p a:hover, .popup .popup-content li a:hover, .popup .popup-content h1 a:hover, .popup .popup-content h2 a:hover, .popup .popup-content h3 a:hover, .popup .popup-content h4 a:hover, .popup .popup-content h5 a:hover, .popup .popup-content h6 a:hover {
        opacity: 0.8; }
    .popup .popup-content .popup-inner-content {
      height: 100%; }
  .popup .popup-actions {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    font-size: 5em;
    position: absolute;
    bottom: 0em;
    width: 100%; }
    .popup .popup-actions .action-restart {
      background-image: url("/images/popup/popup_btn_restart.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      height: 2em;
      width: 2em; }
    .popup .popup-actions .action-home {
      background-image: url("/images/popup/popup_btn_home.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      height: 2em;
      width: 2em; }
    .popup .popup-actions .action-back {
      background-image: url("/images/popup/popup_btn_back.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      height: 2em;
      width: 2em; }
    .popup .popup-actions .popup-action {
      transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
      -moz-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
      -o-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      cursor: pointer;
      margin-right: 2em; }
      .popup .popup-actions .popup-action:hover {
        transform: scale(1.1); }
      .popup .popup-actions .popup-action:last-child {
        margin-right: 0em; }
    .popup .popup-actions .action-custom {
      font-size: 0.35em; }
  .popup.active .popup-container {
    transform: scale(1); }
  .popup:not(.active) {
    pointer-events: none; }
    .popup:not(.active) .popup-overlay {
      opacity: 0 !important; }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

.language-switcher {
  position: absolute;
  top: 5vh;
  right: 5vh;
  font-size: 3vh;
  z-index: 10;
  color: #4644B7;
  text-transform: uppercase;
  transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
  -moz-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
  -o-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  font-family: 'Baloo Thambi', cursive;
  font-weight: normal; }
  .language-switcher:hover {
    transform: scale(1.1); }

/**
 * Style mixins
 */
/**
 * Animation Mixin
 */
/**
 * Base mixins
 */
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(1.1, 0.9) translateY(0); }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px); }
  50% {
    transform: scale(1.05, 0.95) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(-7px); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

body, p {
  font-family: 'Open Sans', sans-serif; }

h1, h2, h3, h4, h5, h6 {
  font-family: 'Baloo Thambi', cursive;
  font-weight: normal; }

a {
  text-decoration: inherit;
  color: inherit; }

.f-title {
  font-family: 'Baloo Thambi', cursive;
  font-weight: normal; }

.f-body {
  font-family: 'Open Sans', sans-serif; }

.grow-hover {
  transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
  -moz-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
  -o-transition: transform 0.3s cubic-bezier(0.05, 0.65, 0.49, 1.84);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer; }
  .grow-hover:hover {
    transform: scale(1.1); }

.center {
  display: flex;
  justify-content: center;
  align-items: center; }

.simple-content {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 1em; }
  .simple-content > img {
    -ms-flex-shrink: 0;
    flex-shrink: 0;
    max-height: 10em; }
  .simple-content .html-content {
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1; }
  .simple-content.image-left > img {
    order: 0;
    margin-right: 1.5em; }
  .simple-content.image-right > img {
    order: 2;
    margin-left: 1.5em; }
  .simple-content.image-right .html-content {
    text-align: right; }
  .simple-content.align-left > .html-content {
    text-align: left; }
  .simple-content.align-right > .html-content {
    text-align: right; }
  .simple-content.image-top {
    -moz-flex-direction: column;
    flex-direction: column;
    margin-top: 60px; }
    .simple-content.image-top > img {
      -ms-flex-shrink: 0;
      flex-shrink: 0;
      max-height: none;
      max-width: 90%;
      display: block;
      max-height: 250px; }
  @media only screen and (max-width: 660px) {
    .simple-content {
      -moz-flex-direction: column;
      flex-direction: column; }
      .simple-content.image-right .html-content {
        text-align: left; }
      .simple-content.image-left > img {
        order: 2; } }

