@import '../../../../assets/scss/commons/commons.scss';

.locker-game-main {
    @include fullsize();

    .go-button {
        width: 40%;
    }

    .content {
        .animated-element {
            height: 100%;
        }

        p {
            @include titleFont();
            font-size: 6em;
            margin-top: 0px;
        }

        .btn-next {
            font-size: 3em !important;
        }
    }

    .step {
        @include flexbox();
        @include flex-direction(column);
        @include justify-content(center);
        height: 100%;
    }

    .step-0, .step-2 {
        padding-bottom: 30%;
    }

    .step-1, .step-3 {
        padding-bottom: 10%;
    }

    .dark-items {
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);
        width: 80%;
        margin-top: 2em;

        .list-item {
            padding: 3.5%;

            img {
                display: block;
                width: 100%;
            }
        }
    }

    .locker {
        img {
            display: block;
            height: 100%;
        }

        &.active {
            cursor: pointer;
        }
    }

    .timer {
        margin-top: 4em;

        .text {
            @include bg-full('/images/game/locker/time_bg.png');
            @include titleFont();
            display: inline-block;
            padding: 1.2em 2em 1em 2em;
            font-size: 4em;
            position: relative;
        }

        .countdown {
            @include bg-cover('/images/game/locker/countdown_bg.png');
            background-position: left;
            font-size: 3em;
            width: 1.4em;
            height: 1.4em;
            position: absolute;
            right: -1.1em;
            top: -0.1em;
            text-align: center;
            line-height: 1.35em;
            color: #231f20;

            &.alert {
                color: #c32c36;
                background-position: right;
            }

            span {
                font-size: 0.8em;
            }
        }
    }

    .current-item {
        @include bg-full('/images/game/locker/item_bg.png');
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);
        display: inline-block;
        width: 59%;
        height: 12em;
        padding: 0% 22% 1.5% 4%;
        position: relative;

        .text {
            font-size: 3em;
            font-weight: 600;
            height: 100%;
            @include flexbox();
            @include align-items(center);
        }

        img {
            max-height: 15em;
            position: absolute;
            right: 8%;
            bottom: 8%;
        }
    }

    .item-click {
        position: absolute;
        //background-color: rgba(255, 255, 255, 0.5);
    }
}