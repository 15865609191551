@import '../../../../assets/scss/commons/commons.scss';

.extracurricular-game-main {
    @include fullsize();

    .activity-ball {
        font-size: 2vh;
        position: relative;
        z-index: 1;
        
        &.text {
            background-color: white;
        }

        .ball-content {
            @include grow-hover();
            //@include flex-center-content();
            width: 100%;
            height: 100%;
            padding: 5%;

            img {
                width: 120%;
                display: block;
                pointer-events: none;
                position: absolute;
                left: -10%;
                top: -10%;
            }
        }

        &:hover {
            z-index: 2;
        }
    }

    .world {
        position: relative;
        top: -400px;
    }

    .instructions {
        @include bg-full('/images/game/extracurricular/instructions_bg.png');
        background-repeat: no-repeat;
        position: relative;
        z-index: 5;

        .school {
            @include bg-full('/images/game/extracurricular/school_bg.png');
            @include titleFont();
            position: absolute;
            font-size: 2.35em;
            padding: 1.5em 2em 2em 1em;
            text-transform: uppercase;
            left: -5%;
            width: 45%;
            text-align: center;
        }

        .text {
            font-size: 1.75em;
            padding-left: 45%;
            padding-right: 10%;
            padding-top: 2.5em;
        }

        .prev-button {
            position: absolute;
            bottom: -1em;
            right: 4em;
            font-size: 2em !important;
        }
    }
}

.extracurricular-popup-content {
    text-align: left;

    img {
        display: block;
        width: 60%;
        margin: auto;
    }

    /*.informations {
        margin-bottom: 2em;
    }*/

    .popup-actions {
        font-size: 1em;
    }

    .schools {
        list-style: none;
        padding: 0px;

        li {
            display: inline-block;
            margin-right: 1em;
            white-space: nowrap;
        }
    }

    h3 {
        margin-bottom: 0px;
    }

    ul {
        margin: 15px 0px 0px 0px;
    }
}