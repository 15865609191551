// Layout
$content-width : 1440px;

// Colors
$color-teal : #2ecdaa;
$color-teal-light : #e6f9f7;
$color-orange : #ff8e09;
$color-yellow : #ffe454;
$color-green : #c6ef84;
$color-blue : #3080e3;
$color-purple : #7c56dd;
$color-blue-purple : #666bff;
$color-dark-blue : #4644B7;