@import '../../../assets/scss/commons/commons.scss';

.game-popup {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .game-popup-description {
        font-size: 0.9em;
        line-height: 1.4;
    }
}

.btn-home {
    @include bg-full('/images/popup/popup_btn_home.png');
    height: 3em;
    width: 3em;
    margin: 0.5em 0 0 0.5em;
}

.game-map {
    @include fullsize();
    position: relative;

    .map-bg {
        @include linearGradient(#ffffff, #bbd2e4);
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0px;
        left: 0px;
        transition: 1s filter;
    }

    .map-main {
        width: 100%;
        height: 100%;
    }

    .btn-back {
        position: absolute;
        left: 2vh;
        top: 2vh;
    }

    &.overlay-start, &.overlay-end {
        .map-bg {
            filter: blur(4px);
        }
    }

    .map-overlay {
        display: flex;
        width: 100%;
        height: 100vh;
        padding: 10vh 10% 0;
        position: fixed;

        .overlay-character {
            width: 40%;
            height: 100%;
            overflow: hidden;

            img {
                width: 100%;
            }
        }

        .overlay-text {
            width: 60%;
            padding: 6%;

            p {
                margin-bottom: 4vh;
                line-height: 1.4;

                @media only screen and (max-width: 1280px) {
                    font-size: 20px;
                }

                @media only screen and (max-width: 800px) {
                    font-size: 14px;
                }
            }

            @media only screen and (max-width: 800px) {
                padding: 2%;
            }
        }

        .text-container {
            background-image: url('/images/popup/popup_bg.png');
            background-size: 100% 100%;
            padding: 4vh 8vh 6vh 8vh;
            text-align: center;
        }
    }
}