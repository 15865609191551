/**
 * Style mixins
 */

@mixin button() {
    @include box-shadow(1px, 1px, 1px, rgba(0, 0, 0, 0.24));
    cursor: pointer;
    display: inline-block;
    padding: 1em 1.4em;
    text-transform: uppercase;
    color: white;
    background-color: $color-orange;
    font-weight: bold;
    font-size: 22px;
}

@mixin titleFont() {
    font-family: 'Baloo Thambi', cursive;
    font-weight: normal;
}

@mixin bodyFont() {
    font-family: 'Open Sans', sans-serif;
}

/**
 * Animation Mixin
 */

$easeOutBack : cubic-bezier(.05,.65,.49,1.84);

@mixin grow-hover($size : 1.1, $ease : $easeOutBack) {
    @include transition( transform .3s $easeOutBack);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;

    &:hover {
        transform: scale($size);
    }
}

@mixin grow-hover-origin($origin : center, $size : 1.1, $ease : $easeOutBack) {
    @include transition(transform .3s $easeOutBack);
    transform-origin: $origin;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;

    &:hover {
        transform: scale($size);
    }
}

/**
 * Base mixins
 */

 @mixin transition($transition) {
    transition: $transition;
    -moz-transition: $transition;
    -o-transition: $transition;
 }

 @mixin linearGradient($top, $bottom){
    background: $top; /* Old browsers */
    background: -moz-linear-gradient(top,  $top 0%, $bottom 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  $top 0%,$bottom 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  $top 0%,$bottom 100%); /* IE10+ */
    background: linear-gradient(to bottom,  $top 0%,$bottom 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

@mixin container($width: $content-width) {
    max-width: $width;
    margin: 0px auto;
}

@mixin reset-list() {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

@mixin absolute-center() {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@mixin fullsize() {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

@mixin fullscreen() {
    @include fullsize();
    position: absolute;
}

@mixin game-background($bg-image) {
    @include fullscreen();
    background-image: url($bg-image);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

@mixin bg-full($url) {
    background-image: url($url);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

@mixin bg-contain($url) {
    background-image: url($url);
    background-size: contain;
    background-repeat: no-repeat;
}

@mixin bg-cover($url) {
    background-image: url($url);
    background-size: cover;
    background-repeat: no-repeat;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow:inset $top $left $blur $color;
        -moz-box-shadow:inset $top $left $blur $color;
        box-shadow:inset $top $left $blur $color;
    } @else {
        -webkit-box-shadow: $top $left $blur $color;
        -moz-box-shadow: $top $left $blur $color;
        box-shadow: $top $left $blur $color;
    }
}

@mixin flex-center-content() {
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
}

/// Stroke font-character
/// @param  {Integer} $stroke - Stroke width
/// @param  {Color}   $color  - Stroke color
/// @return {List}            - text-shadow list
@function stroke($stroke, $color) {
    $shadow: ();
    $from: $stroke*-1;
    @for $i from $from through $stroke {
        @for $j from $from through $stroke {
            $shadow: append($shadow, $i*1px $j*1px 0 $color, comma);
        }
    }
    @return $shadow;
}

/// Stroke font-character
/// @param  {Integer} $stroke - Stroke width
/// @param  {Color}   $color  - Stroke color
/// @return {Style}           - text-shadow
@mixin stroke($stroke, $color, $append: false) {
    @if $append {
        text-shadow: append(stroke($stroke, $color), $append, comma);
    } @else {
        text-shadow: stroke($stroke, $color);
    }
}

// --------------------------------------------------
// Flexbox SASS mixins
// The spec: http://www.w3.org/TR/css3-flexbox
// --------------------------------------------------

// Flexbox display
@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin flex($values) {
    -webkit-box-flex: $values;
    -moz-box-flex: $values;
    -webkit-flex: $values;
    -ms-flex: $values;
    flex: $values;
}

@mixin flex-direction($direction) {
    -webkit-flex-direction: $direction;
    -moz-flex-direction: $direction;
    -ms-flex-direction: $direction;
    flex-direction: $direction;
}

@mixin flex-wrap($wrap) {
    -webkit-flex-wrap: $wrap;
    -moz-flex-wrap: $wrap;
    -ms-flex-wrap: $wrap;
    flex-wrap: $wrap;
}

@mixin flex-flow($flow) {
    -webkit-flex-flow: $flow;
    -moz-flex-flow: $flow;
    -ms-flex-flow: $flow;
    flex-flow: $flow;
}

@mixin order($val) {
    -webkit-box-ordinal-group: $val;  
    -moz-box-ordinal-group: $val;     
    -ms-flex-order: $val;     
    -webkit-order: $val;  
    order: $val;
}

@mixin flex-grow($grow) {
    -webkit-flex-grow: $grow;
    -moz-flex-grow: $grow;
    -ms-flex-grow: $grow;
    flex-grow: $grow;
}

@mixin flex-shrink($shrink) {
    -webkit-flex-shrink: $shrink;
    -moz-flex-shrink: $shrink;
    -ms-flex-shrink: $shrink;
    flex-shrink: $shrink;
}

@mixin flex-basis($width) {
    -webkit-flex-basis: $width;
    -moz-flex-basis: $width;
    -ms-flex-basis: $width;
    flex-basis: $width;
}

@mixin justify-content($justify) {
    -webkit-justify-content: $justify;
    -moz-justify-content: $justify;
    -ms-justify-content: $justify;
    justify-content: $justify;
-ms-flex-pack: $justify;
}

@mixin align-content($align) {
    -webkit-align-content: $align;
    -moz-align-content: $align;
    -ms-align-content: $align;
    align-content: $align;
}

@mixin align-items($align) {
    -webkit-align-items: $align;
    -moz-align-items: $align;
    -ms-align-items: $align;
    align-items: $align;
}

@mixin align-self($align) {
    -webkit-align-self: $align;
    -moz-align-self: $align;
    -ms-align-self: $align;
    align-self: $align;
}