@import '../../../assets/scss/commons/commons.scss';

.game .info-button {
    @include grow-hover();

    position: absolute;
    top: 3vh;
    right: 10vh;
    width: 10vh;
    max-width: 105px;
    z-index: 10;

    img {
        display: block;
        width: 100%;
    }
}