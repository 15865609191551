@import '../../../assets/scss/commons/commons.scss';

.informations-component {
   height: 100%;
   width: 100%;
   overflow: hidden;

   .slides {
       position: relative;
       @include transition(left 0.5s);
   }

   .slide {
        @include flexbox();
        @include flex-direction(column);
        @include justify-content(center);
        text-align: left;
        position: relative;
        float: left;
   }

   &:after {
        display: block;
        content: '';
        clear: both;
   }

   ul {
        list-style-image: url('/images/general/list-item.png') !important;
        padding-left: 1.5em !important;
        @include bodyFont();

        li {
             margin-bottom: 0.6em;

             &:last-child {
                  margin-bottom: 0px;
             }
        }
   }

   a {
        text-decoration: underline;
   }

   blockquote {
     @include bg-full('/images/general/quote.png');
     margin-block-start: 0em;
     margin-block-end: 0em;
     margin-inline-start: 0px;
     margin-inline-end: 0px;
     padding: 0.5em 2em 1.4em 2em;
     margin-top: 2em;
   }

   h2 {
     font-size: 1.5em !important;
     margin: 0.8em 0px 0.5em 0px !important;
     color: $color-orange;
   }
}