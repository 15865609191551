@import '../../../../../assets/scss/commons/commons.scss';

.answer-handle {
    user-drag: none;
    user-select: none;

    .answer-handle_inner {
        height: 100%;

        display: flex;
        justify-content: space-between;
        align-self: center;
    }

    .answer-handle_direction {
        display: flex;
        align-self: center;

        &.button {
            @include bg-full('/images/game/deplacements/bg_answer_button.svg');
            @include grow-hover();
            height: 60em;
            width: 60em;
            display: flex;
            align-items: center;
            justify-content: center;
            
            .label {
                font-size: 15em;
                color: white;
                text-shadow: 0.03em 0.03em 0px rgb(202, 96, 10);
                @include titleFont();
                text-transform: uppercase;
            }
        }
    }
}