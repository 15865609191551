@import '../../../../../assets/scss/commons/commons.scss';

.lock-game-main .instructions {
    @include bg-contain('/images/game/lock/instructions_bg.png');
    height: 100%;
    position: relative;
    z-index: 6;

    .content {
        @include flexbox();
        @include align-items(center);
        font-size: 1em;
        width: 100%;
        height: 80%;
        position: relative;
        left: -10%;

    }

    .step {
        @include bg-contain('/images/game/lock/header_number_bg.png');
        @include titleFont();
        background-position: center;
        font-size: 32em;
        color: #3b3b3b;
        padding: 1.5em;
        margin-right: 1em;
    }

    .text {
        color: #828282;
        font-weight: 500;
        font-size: 13em;
    }

    .next {
        @include bg-contain('/images/game/lock/skip_instruction.png');
        @include grow-hover();
        position: absolute;
        bottom: -10%;
        right: 8%;
        background-position: center;
        width: 8%;

        &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }
    }

    img {
        height: 1em;

        &.reversed {
            transform: scaleX(-1);
        }
    }
}