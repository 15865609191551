@import '../../../../assets/scss/commons/commons.scss';

.professionals-game-main {
    @include fullsize();

    .characters {
        @include flexbox();
        height: 66vh;
        width: 100%;
        padding: 0px 5%;
        position: relative;
        top: 34vh;
        z-index: 1;
    }

    .character {
        @include grow-hover-origin(bottom, 1.05);
        width: 25%;
        position: relative;

        img {
            display: block;
            height: 100%;
            margin: auto;
        }

        .name-container {
            @include flex-center-content();
            @include titleFont();
            position: absolute;
            height: 25%;
            text-align: center;
            font-size: 3.4vh;
            width: 100%;
            padding: 0 20%;
        }

        &.character-0 .name-container { top: 37%; transform: rotate(-1.7deg); }
        &.character-1 .name-container { top: 34%; transform: rotate(1deg); }
        &.character-2 .name-container { top: 38.8%; transform: rotate(1.1deg); }
        &.character-3 .name-container { top: 40.2%; transform: rotate(0.7deg); }
        &.character-4 .name-container { top: 39.3%; transform: rotate(2deg); }
        &.character-5 .name-container { top: 37.5%; transform: rotate(1.7deg); }

        &.selected-option {
            opacity: 0;
        }

        &.character-temp {
            position: absolute;
            height: 66vh;
            z-index: 600;
            bottom: 0px;
            pointer-events: none;
            left: 21%;
        }
    }

    .instructions {
        @include bg-full('/images/game/professionals/instruction_bg.png');
        position: absolute;
        width: 65%;
        right: 15%;
        top: 3%;
        font-size: 1vh;
        padding: 3% 8% 4% 8%;

        .name-box {
            @include bg-full('/images/game/professionals/name_bg.png');
            position: absolute;
            font-size: 2.8em;
            font-weight: 600;
            padding: 1.5em 1.2em 1.2em 1.5em;
            top: 10%;
            left: -5%;

        }

        .text {
            font-size: 2.8em;
        }

        .description {
            font-weight: 600;
            margin-bottom: 1em;
        }
    }
}

.professionals-success-popup-content {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(center);
    text-align: center;
    padding-left: 40%;
    height: 100%;
    
    p {
        text-align: left;
        font-size: 1.1em;
        margin: 0.5em 0em;
    }

    h2 {
        text-transform: uppercase;
        font-size: 2.4em;
        color: $color-purple;
        margin-bottom: 0.1em;
    }

    .next-button {
        margin-top: 1.0em;
    }
}