@import './fonts';
@import '../../../node_modules/modern-normalize/modern-normalize.css';
@import 'commons/commons';

body, p {
	@include bodyFont();
}

h1, h2, h3, h4, h5, h6 {
	@include titleFont();
}

a {
	text-decoration: inherit;
	color: inherit;
}

.f-title {
	@include titleFont();
}

.f-body {
	@include bodyFont();
}

.grow-hover {
	@include grow-hover();
}

.center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.simple-content {
	@include flexbox();
	@include align-items(center);
	margin-bottom: 1em;

	> img {
		@include flex-shrink(0);
		max-height: 10em;
	}

	.html-content {
		@include flex-grow(1);
	}

	&.image-left {
		> img {
			@include order(0);
			margin-right: 1.5em;
		}
	}

	&.image-right {
		> img {
			@include order(2);
			margin-left: 1.5em;
		}

		.html-content {
			text-align: right;
		}
	}

	&.align-left {
		> .html-content {
			text-align: left;
		}
	}

	&.align-right {
		> .html-content {
			text-align: right;
		}
	}

	&.image-top {
		@include flex-direction(column);
		margin-top: 60px;

		> img {
			@include flex-shrink(0);
			max-height: none;
			max-width: 90%;
			display: block;
			max-height: 250px;
		}
	}

	@media only screen and (max-width: 660px) {
		@include flex-direction(column);

		&.image-right {
			.html-content {
				text-align: left;
			}
		}

		&.image-left {
			> img {
				@include order(2);
			}
		}
    }
}