@import '../../../../../assets/scss/commons/commons.scss';

.lock-game-main .lock {
    position: relative;
    height: 100%;

    .base {
        display: block;
        height: 100%;
    }

    .ring {
        position: absolute;
        width: 36%;
        left: 29.5%;
        top: 42.5%;

        > img {
            position: relative;
            z-index: 0;
            display: block;
            width: 100%;
        }

        > svg {
            position: absolute;
            z-index: 1;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
        }

        text {
            fill: white;
        }
    }
}