@import '../../../../../assets/scss/commons/commons.scss';

.lock-game-main .level-display {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(space-between);

    .level {
        margin-bottom: 2em;

        &:last-child {
            margin-bottom: 0;
        }

        .level-container {
            width: 100%;
            position: relative;

            &:after {
                content: "";
                display: block;
                padding-bottom: 125%;
            }
        }

        .level-content {
            @include bg-contain('/images/game/lock/step_number_bg.png');
            @include flex-center-content();
            @include titleFont();

            position: absolute;
            width: 100%;
            height: 100%;
            padding-top: 0.2em;
            font-size: 14em;
            color: #6d4d4e;
        }

        .completed {
            position: absolute;
            height: 139%;
            top: -21%;
            left: -44%;
            display: block;
        }
    }
}