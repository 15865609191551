@import '../../../../../assets/scss/commons/commons.scss';

.lock-game-main .code-display {
    padding-top: 7em;
    padding-bottom: 2em;
    padding-left: 3em;

    &.code-display-fr {
        @include bg-contain('/images/game/lock/numbers_bg_fr.png');
    }

    &.code-display-en {
        @include bg-contain('/images/game/lock/numbers_bg_en.png');
    }

    .combination {
        @include flexbox();
        @include flex-direction(column);
        @include justify-content(space-between);
        height: 100%;
        padding: 20% 15%;
    }

    .combination-number {
        @include titleFont();
        text-align: center;
        font-size: 17em;
        @include transition(transform .3s $easeOutBack);
        color: #3b3b3b;

        &.completed {
            transform: scale(1.2);
            color: #129977;
        }
    }
}