@import '../../../../assets/scss/commons/commons.scss';

.agenda-game-main {
    @include fullsize();

    .ui {
        .animated-element {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
        }
    }

    .question {
        @include fullsize();
        @include flexbox();
        font-size: 2.2vh;
        flex-direction: column;
        padding: 2em;

        .question-container {
            height: 100%;
            display: flex;
            align-items: center;

            .question-content {
                @include bg-full('/images/game/professionals/instruction_bg.png');
                position: relative;
                margin: auto;
            }

            .title{
                @include bg-full('/images/game/professionals/name_bg.png');
                position: absolute;
                font-size: 1.2em;
                padding: 1.5em 1.2em 1.2em 1.5em;
                top: -2em;
                left: 50%;
                transform: translateX(-50%);
                font-family: 'Baloo Thambi', cursive;
            }

            .text {
                padding: 2.5em 5em 3em 6em;
            }
        }

        .answers {
            display: flex;
            height: 60%;
        }

        .answer {
            @include bg-full('/images/game/agenda/instructions_bg.png');
            padding: 8vh 5vh 9vh 9vh;
            width: 50%;
            position: relative;
            text-align: center;

            .title{
                @include bg-full('/images/game/professionals/name_bg.png');
                position: absolute;
                font-size: 1em;
                padding: 1.5em 1.2em 1.2em 1.5em;
                top: -1em;
                left: 50%;
                transform: translateX(-50%);
                z-index: 5;
                font-family: 'Baloo Thambi', cursive;
            }

            .button {
                position: absolute;
                bottom: -0.5em;
                width: 8em;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}

.agenda-result-image {
    display: inline-block;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    height: 100%;

    img {
        display: block;
        height: 100%;
        max-width: 100%;

        &.agenda-layer {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
        }
    }
}