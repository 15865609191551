@import '../../../assets/scss/commons/commons.scss';

.language-switcher {
    position: absolute;
    top: 5vh;
    right: 5vh;
    font-size: 3vh;
    z-index: 10;
    color: $color-dark-blue;
    text-transform: uppercase;
    
    @include grow-hover();
    @include titleFont();
}