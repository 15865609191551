@import '../../../assets/scss/commons/commons.scss';

.static-game-header {
    height: 90vh;
    width: 100%;
    background-color: $color-yellow;
    position: relative;
    padding-top: 4em;
    font-size: 1vh;

    .static-game-header__description {
        font-size: 2.7em;
        margin-top: 0.6em;
        font-weight: normal;
    }

    .container {
        @include bg-contain('/images/static/hero_kids.png');
        height: 100%;
        background-position: right;
        margin-right: 15px;
    }

    .content {
        @include flexbox();
        @include flex-direction(column);
        height: 100%;
        width: 60%;

        .text {
            padding-left: 100px;
            padding-top: 15em;

            h1 {
                text-shadow: 8px 8px 20px rgba(0, 0, 0, 0.4);
            }

            .logo {
                @include flexbox();
                @include align-items(center);
                margin-bottom: 2%;
                position: absolute;
                z-index: 2;
                top: 2%;
                height: 15em;

                img {
                    height: 100%;
                }
            }
            
            .credits {
                @include titleFont();
                font-size: 1.7em;
                margin-left: 2em;
                margin-top: 1em;
            }
        }

        .hand {
            @include flex-grow(1);
            overflow: hidden;
            padding-left: 4%;
            padding-top: 2%;

            .hand__container {
                position: relative;
            }

            .hand__phone {
                width: 70%;
                display: block;
            }

            .btn-play {
                position: absolute;
                top: 20%;
                right: 20%;
                display: inline-block;
                margin-top: 1em;
                width: 35%;
                max-width: 350px;
                z-index: 10;

                img {
                    display: block;
                    width: 100%;
                }
            }
        }
    }

    h1 {
        font-weight: 700;
        margin: 0px;
        font-size: 8em;
        color: #ffffff;
        font-weight: normal;
        line-height: 0.9;
        margin-bottom: 0.2em;
    }

    p {
        color: #000000;
        font-size: 3em;
        margin: 0px;
        font-weight: bold;
    }

    @media only screen and (max-width: 1024px) {
        height: auto;
        font-size: 1vw;

        .container {
            margin-right: 0px;
        }

        .content {
            width: 100%;

            .hand {
                img {
                    width: 40%;
                }
            }

            .text {
                padding: 0px 9em;

                .logo {
                    @include flex-direction(column);
                    @include justify-content(center);
                    width: 100%;
                    position: relative;
                    top: -2em;
                }

                .credits {
                    font-size: 2em;
                    margin-left: 0em;
                    margin-top: 1em;
                }
            }
        }

        p {
            max-width: 50%;
        }

        .container {
            background-size: 50%;
            background-position: bottom right 15px;
        }
    }
}