@import '../../../assets/scss/commons/commons.scss';

.exit-button {
    position: absolute;
    z-index: 10;
    top: 2vh;
    left: 2vh;

    .exit-button__icon {
        @include bg-full('/images/popup/popup_btn_home.png');
        height: 3em;
        width: 3em;
    }
}