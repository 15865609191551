@import '../../../assets/scss/commons/commons.scss';

.game .menu {
    @include fullsize();
    @include titleFont();
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 999;
    pointer-events: none;
    font-size: 2.5vh;
    
    .menu-bg {
        width: 100%;
        height: 90%;
        position: relative;
        opacity: 0.95;
        top: 100%;
        @include transition(top .6s ease-in-out);

        &:before {
            position: absolute;
            top: 15%;
            content: '';
            display: block;
            width: 100%;
            height: 65%;
            background-color: black;
            transform: skew(0deg, -2.5deg);
        }
        &:after {
            position: absolute;
            top: 35%;
            content: '';
            display: block;
            width: 100%;
            height: 65%;
            background-color: black;
            transform: skew(0deg, 2deg);
        }
    }

    .menu-content {
        @include flex-center-content();
        @include flex-direction(column);
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 100%;
        @include transition(top .6s ease-in-out 0.1s);
    }

    .menu-title {
        color: #be2222;
        font-size: 5em;
        text-transform: uppercase;
    }

    .menu-item {
        @include grow-hover();
        color: #ffffff;
        font-size: 2em;
        margin-bottom: 0.5em;
    }

    .close-button {
        @include grow-hover();
        position: absolute;
        top: 8%;
        right: 2%;
        color: #ffffff;
        font-size: 5em;

        span {
            @include transition(transform .3s $easeOutBack 0s);
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            transform: scale(0);
            display: block;
        }
    }

    &.menu-opened {
        pointer-events: inherit;

        .menu-bg {
            top: 0%;
        }

        .menu-content {
            top: 0%;
        }

        .close-button {    
            span {
                @include transition(transform .3s $easeOutBack 0.5s);
                transform: scale(1);
            }
        }
    }
}