@import '../../../assets/scss/commons/commons.scss';

.static-footer {
    background-color: $color-blue-purple;
    border-top: 1px solid #d3d3d3;
    padding: 60px 80px 30px 80px;

    .copyright {
        margin-top: 60px;
        text-align: center;

        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .content {
        max-width: 1400px;
        margin: auto;
        font-size: 16px;
        color: white;

        @include flexbox();
        @include justify-content(space-between);
    }

    .logo {
        margin-bottom: 10px;
        width: 262px;
    }

    .form-cta {

        .text {
            @include titleFont();
            margin-right: 20px;
            font-size: 36px;
            line-height: 0.8;
            color: #ffffff;

            span {
                font-size: 22px;
            }
        }
    }

    .cta {
        @include grow-hover();
        @include titleFont();
        text-align: center;
        font-size: 24px;
        line-height: 0.8;
        text-transform: uppercase;
        @include bg-full('/images/static/footer_btn.png');
        padding: 1.3em 1.7em;
        display: inline-block;
        margin-top: 1em;
        white-space: nowrap;
        color: black;
        margin-left: 45px;
    }

    .logo-column {
        max-width: 270px;
    }

    .column {
        &:not(:first-child) {
            margin-left: 60px;
        }

        /*&:nth-child(3) {
            @include flex-grow(1);
        }

        &:nth-child(4) {
            @include flex-shrink(1);
        }*/

        ul {
            padding: 0px;
            list-style: none;

            li {
                margin-bottom: 1em;
            }

            a:hover {
                text-decoration: underline;
            }

            li {
                margin-bottom: 0.4em;
            }
        }

        h3 {
            margin-bottom: 0.1em;

            &:not(:first-child) {
                margin-top: 2em;
            }
        }
    }

    .logo-rrm {
        width: 100%;
        max-width: 300px;
        margin: 1.4em 0;
        display: block;
        min-width: 230px;
    }

    .logo-qc {
        width: 100%;
        max-width: 200px;
        margin: 1.7em 0;
        display: block;
    }

    .last-update {
        color: black;
        margin-top: 0px;
        font-size: 0.8em;
        text-align: center;
    }

    .static-footer__links {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media only screen and (max-width: 1362px) {
        .form-cta {    
            .text {
                font-size: 26px;
    
                span {
                    font-size: 14px;
                }
            }
        }
    }

    @media only screen and (max-width: 1215px) {
        .content {    
            @include flex-direction(column);
            @include align-items(center);

            text-align: center;
        }

        .form-cta {    
            @include flex-direction(column);
        }

        .cta {
            margin-top: 25px;
            margin-bottom: 45px;
            margin-left: 0px;
        }

        .column {
            &:not(:first-child) {
                margin-left: 0px;
            }
        }

        .logo-rrm {
            margin-left: auto;
            margin-right: auto;
        }
    
        .logo-qc {
            margin-left: auto;
            margin-right: auto;
        }
    }
}