@import '../../../assets/scss/commons/commons.scss';

.scroll-bar {
    position: relative;
    height: 100%;
    text-align: left;

    .indicator {
        width: 100%;
        height: 50px;
        position: absolute;
        opacity: 1;
        pointer-events: none;

        @include transition(opacity 0.5s);

        &.hidden {
            opacity: 0;
        }

        &.bottom {
            bottom: 0px;
            @include linearGradient(#ffffff00, #ffffffff);
        }

        &.top {
            top: 0px;
            @include linearGradient(#ffffffff, #ffffff00);
        }
    }

    [data-scrollbar] {
        padding-right: 2em;
    }
}