@import '../../../../assets/scss/commons/commons.scss';

.game-map .icon {
    position: absolute;
    
    .icon-container {
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 12vh;
        position: relative;
    }

    a {        
        display: block;
        width: 100%;
        height: 100%;
    }

    .animated-content {
        width: 100%;
        height: 100%;
    }

    .icon-content {
        @include flexbox();
        @include flex-direction(column);
        @include align-items(center);
        @include justify-content(center);
        @include border-radius(50%);
        @include grow-hover();

        width: 100%;
        height: 100%;

        img {
            display: block;
            width: 100%;
            position: relative;
        }
    }
}