@import '../../../assets/scss/commons/commons.scss';

.game {
    @include fullsize();
    font-size: 24px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
    touch-action: manipulation;

    .game-bg {
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0px;
        left: 0px;
    }
    
    .iconContainer{
        width:200px;
        height:200px;
        position:absolute;
        left:50%;
        top:50%;
        margin-left:-100px;
        margin-top:-100px;
    }
    
    .phone{
        @include flex-center-content();
        position:relative;
        left:50%;
        margin-left:-50px;
        width:0;
        height:0;
        border:0 solid #000;
        background:#a9a9a9;
        border-radius:10px;
        box-sizing:border-box;

        svg {
            font-size:50px;
            color:#454545;
            opacity:0;
        }
    }

    .overlay {
        pointer-events: none;

        .hint {
            text-transform: uppercase;
            font-size: 15px;
            text-align: center;
            white-space: nowrap;
            margin-top: 20px;
            font-weight: 600;
            opacity: 0;
            pointer-events: none;
            transition: opacity 1s;   
        }
    }
    
    @media only screen and (orientation: portrait) {
        .overlay{
            pointer-events: all;
            display:block;
            background-color: #ffffff;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1000000;
            top: 0px;
            left: 0px;

            .hint {
                opacity: 1;
                transition-delay: 1s;
            }
        }
    
        .overlay .phone{
            animation:
                sizeIncrease .5s forwards ease, 
                borderIncrease .5s .5s forwards ease, 
                rotateRight 2.8s 1s forwards ease infinite;
        }
    
        .overlay .phone svg {
            animation:
                fadeIn .5s .8s forwards ease;
        }
    
        .overlay .iconContainer p {
            animation:
                fadeIn .5s 1.3s forwards ease;
        }
    }
    
    /* KEYFRAMES
    ------------------------------------------- */
    /* Animate width + height */
    @keyframes sizeIncrease {
      0%   { width: 0; height:10px; }
        50%  { width: 100px; height: 10px; }
      100% { width: 100px; height:160px; }
    }
    /* Add borders */
    @keyframes borderIncrease { 
        100% { border-width:20px 10px; } 
    }
    
    /* fade-in  */
    @keyframes fadeIn { 
        100% { color:#000; opacity:1; } 
    }
    
    /* Rotate device */
    @keyframes rotateRight { 
        25% { transform: rotate(90deg); } 
        100% { transform: rotate(90deg); } 
    }
}