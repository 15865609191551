@import '../../../assets/scss/commons/commons.scss';

.game .fullscreen-button {
    @include grow-hover();
    position: fixed;
    bottom: 3vh;
    right: 3vh;
    width: 15vh;
    max-width: 100px;
    z-index: 1100;

    img {
        display: block;
        width: 100%;
    }
}