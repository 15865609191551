@import '../../../assets/scss/commons/commons.scss';

.popup {
    z-index: 500;

    .popup-overlay {
        position: fixed;
        z-index: 501;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100vh;
        background-color: black;
        @include transition(opacity .5s);
    }

    .popup-container {
        @include bg-full('/images/popup/popup_bg.png');
        z-index: 511;
        transform: scale(0);
        @include transition(transform .5s);
    }

    .popup-title-container {
        position: absolute;
        top: -4em;
        width: 100%;
        text-align: center;
    }

    .popup-title {
        @include bg-full('/images/popup/popup_title.png');
        @include titleFont();
        display: inline-block;
        font-size: 3.5em;
        text-transform: uppercase;
        color: #3b3b3b;
        padding: 1.2em 1em 0.8em 1em;
        
        span {
            display: inline-block;
            transform: skewY(-3deg);
        }
    }

    .popup-content {
        text-align: center;
        font-size: 2em;
        height: 100%;

        .scroll-content {
            padding-bottom: 30px;
        }

        p, li, h1, h2, h3, h4, h5, h6 {
            a {
                text-decoration: underline;

                &:hover {
                    opacity: 0.8;
                }
            }
        }

        .popup-inner-content {
            height: 100%;
        }
    }

    .popup-actions {
        @include flex-center-content();
        font-size: 5em;
        position: absolute;
        bottom: 0em;
        width: 100%;
        
        .action-restart {
            @include bg-full('/images/popup/popup_btn_restart.png');
            height: 2em;
            width: 2em;
        }

        .action-home {
            @include bg-full('/images/popup/popup_btn_home.png');
            height: 2em;
            width: 2em;
        }

        .action-back {
            @include bg-full('/images/popup/popup_btn_back.png');
            height: 2em;
            width: 2em;
        }

        .popup-action {
            @include grow-hover();
            margin-right: 2em;

            &:last-child {
                margin-right: 0em;
            }
        }

        .action-custom {
            font-size: 0.35em;
        }
    }

    &.active {
        .popup-container {
            transform: scale(1);
        }
    }

    &:not(.active) {
        .popup-overlay {
            opacity: 0 !important;
        }

        pointer-events: none;
        
    }
}